<template>
  <div class="page page--additions animated_content">
    <sequential-entrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated" title="Zamówienia" with-menu/>
      <div class="col-1 no_animation">
        <div class="col info_container">
          <sequential-entrance animation="animate__fadeInUp" tag="div">
            <InfoBox v-for="order in orders" :key="order.id" @click="handleClickOrder(order)" space class="order animate__animated">
              <div class="info_title">{{ order.partner.name }}</div>
              <div class="info_line column space">
                <span class="alternative-text">Data i godzina</span>
                <span>{{ $moment(order.created_at).format('DD.MM.YYYY HH:mm') }}</span>
              </div>
              <div class="info_line column space">
                <span class="alternative-text">Dostawa / odbiór osobisty</span>
                <span v-if="order.delivery_type === 'takeaway'">Odbiór osobisty</span>
                <span v-else>
                  Dostawa, {{ order.delivery_address.street }} {{ order.delivery_address.house_nr }}{{ order.delivery_address.apartment_nr ? `/${order.delivery_address.apartment_nr}` : '' }}, {{ order.delivery_address.postcode }} {{ order.delivery_address.city }}
                </span>
              </div>
              <div class="info_line column space">
                <span class="alternative-text">Zamówienie</span>
                <div class="info_line">
                  <span>{{ getAmount(order.amount) }}</span>
                  <span class="order_price">{{ parseFloat(order.price).toFixed(2).replace('.', ',') }} zł</span>
                </div>
              </div>
              <div class="hr"></div>
              <div class="info_line">
                <span class="info_title">Status</span>
                <OrderStatus :status="order.status"/>
              </div>
            </InfoBox>
          </sequential-entrance>
        </div>
      </div>

      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </sequential-entrance>
    <Drawer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import Drawer from "@/parts/Drawer";
import InfoBox from "@/parts/InfoBox";
import OrderStatus from "@/parts/OrderStatus";

export default {
  name: "Orders",
  components: {
    OrderStatus,
    InfoBox,
    PlainFooter,
    Drawer,
    StickyHeader,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'orders',
      'isLogged',
    ]),
    // itemQuantity() {
    //   if (!this.form.quantity) {
    //     return '';
    //   }
    //   if (this.form.quantity === 1) {
    //     return '1 pozycja';
    //   } else if (this.form.quantity > 1 && this.form.quantity < 5) {
    //     return `${this.form.quantity} pozycje`;
    //   } else {
    //     return `${this.form.quantity} pozycji`;
    //   }
    // },
  },
  watch: {
  },
  methods: {
    getAmount(amount) {
      if (!amount) {
        return '';
      }
      if (amount === 1) {
        return '1 pozycja';
      } else if (amount > 1 && amount < 5) {
        return `${amount} pozycje`;
      } else {
        return `${amount} pozycji`;
      }
    },
    handleClickOrder(order) {
      this.$router.push({ name: 'Order', params: {id: order.id}});
    }
  },
  created() {
    this.$store.dispatch('fetchMyOrders', {restaurantId: this.restaurant_id}).then(() => {

    });
  }
}
</script>

<style scoped lang="scss">
.flex-fill {
  flex: 1;
}
.col-1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    max-width: 600px;
    width: 100%;
    //&:last-of-type {
    //  padding-left: 0;
    //}
    h4 {
      font-weight: 500;
    }
  }
  .menu_container {
    flex: 2;
  }
  .info_container {
    padding-top: 15px;
    .info_title {
      display: flex;
      margin-top: 0;
      margin-bottom: 2px;
      font-weight: 500;
      font-size: 1.1rem;
    }
    .info_line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
      margin-bottom: 2px;
      text-align: left;
      p {
        margin: 0;
        a {
          color: var(--text);
          text-decoration: none;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &.column {
        flex-direction: column;
      }
    }
  }
}
.product_title {
  font-weight: 500;
}
.product_price {
  font-weight: 500;
  color: var(--primary);
}
.order {
  width: 100%;
  cursor: pointer;
  transition: background-color .2s ease;
  .order_price {
    font-weight: 500;
    color: var(--primary);
  }
  &:hover, &:focus {
    background-color: var(--input-background-secondary);
  }
}
.space {
  margin-bottom: 15px !important;
}
.clickable {
  cursor: pointer;
  transition: background-color .2s ease;
  &:hover, &:focus {
    background-color: var(--input-background-secondary);
  }
}
.alternative-text {
  color: var(--text-secondary);
}
.hr {
  width: calc(100% - 30px);
  padding-left: 15px;
  padding-right: 15px;
  height: 1px;
  background-color: var(--border);
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
