<template>
  <div v-if="status === 'CREATED'" class="status--created">Utworzone</div>
  <div v-else-if="status === 'PENDING'" class="status--">Przetwarzanie płatności</div>
  <div v-else-if="status === 'COMPLETED'" class="status--completed">Zatwierdzone (przygotowywanie)</div>
  <div v-else-if="status === 'READY'" class="status--ready">Gotowe (przygotowane)</div>
  <div v-else-if="status === 'READY_TO_PICKUP'" class="status--ready_to_pickup">Gotowe (gotowe do odbioru)</div>
  <div v-else-if="status === 'SEND'" class="status--send">W drodze</div>
  <div v-else-if="status === 'DELIVERED'" class="status--delivered">Dostarczone (zjedzone)</div>
  <div v-else-if="status === 'CANCELED'" class="status--canceled">Anulowane</div>
  <div v-else class="status--other">{{ status }}</div>
</template>

<script>
export default {
  name: "OrderStatus",
  props: {
    status: String,
  }
}
</script>

<style scoped lang="scss">
.status {
  &--created {
    color: var(--warning);
  }
  &--pending {
    color: var(--warning);
  }
  &--completed {
    color: var(--primary);
  }
  &--ready {
    color: var(--success);
  }
  &--ready_to_pickup {
    color: var(--success);
  }
  &--send {
    color: var(--success);
  }
  &--delivered {
    color: var(--info);
  }
  &--canceled {
    color: var(--danger);
  }
  &--other {
    color: var(--text);
  }
}
</style>
