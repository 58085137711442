<template>
  <div class="info-box" :class="{space: space}" v-on="$listeners">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
  props: {
    space: Boolean,
  },

}
</script>

<style scoped lang="scss">
.info-box {
  padding: 16px;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--infobox-shadow);
  &.space {
    margin-bottom: 15px;
  }
}
</style>
