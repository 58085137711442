<template>
  <transition name="slide-fade">
    <div class="drawer-outer" v-show="showDrawer" @click.self="$store.commit('setShowDrawer', false)">
      <div class="drawer">
        <div class="close_container">
          <div class="close_button" @click="$store.commit('setShowDrawer', false)">
            <i class="far fa-times"></i>
          </div>
        </div>

        <div class="avatar_container">
          <div class="avatar_content">
            <img src="@/assets/images/logo/mini.png" alt="Avatar">
          </div>
        </div>

        <div v-if="isLogged" class="user_container"> <!-- TODO: @click="handleElementClick({name: 'Profile'})" -->
          <div class="user_content">
            <span class="name">{{ user.name }}</span>
            <span class="email">{{ user.email }}</span>
          </div>
        </div>

        <div v-for="element in elementsFiltered" @click="handleElementClick(element)" class="element_container" :class="{'active': $route.name === element.name}">
          <div class="element_content">
            {{ element.label }}
          </div>
        </div>
        <div style="flex:1"></div>
        <div class="bottom_container">
          <div class="bottom_content">
            <div class="simple_link" @click="openInNewCard($constants.privacy_policy)">Polityka prywatności</div>
            <div class="simple_link" @click="openInNewCard($constants.regulations)">Regulamin</div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Drawer",
  data() {
    return {
      elements: [
        {label: 'Restauracje', name: 'Home'},
        {label: 'Zamówienia', name: 'Orders', auth: true},
        // {label: 'Zgody', auth: true},
        // {label: 'Powiadomienia', auth: true},
        {label: 'Panel Administracyjny', action: 'goAdmin', admin: true, owner: true},
        {label: 'Wyloguj się', action: 'logout', auth: true},
        {label: 'Zaloguj się', name: 'Login', guest: true},
        {label: 'Załóż konto', name: 'Register', guest: true},
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDrawer',
      'isLogged',
      'user',
    ]),
    elementsFiltered() {
      return this.elements.filter(element => {
        return (element.auth && this.isLogged)
            || (element.guest && !this.isLogged)
            || (!element.auth && !element.guest && !element.admin && !element.owner)
            || (element.admin && this.user.role && this.user.role.permissions === 2)
            || (element.owner && this.user.role && this.user.role.permissions === 4);
      });
    },
  },
  methods: {
    openInNewCard(link) {
      window.open(link, '_blank');
    },
    navigateTo(name) {
      this.$store.commit('setShowDrawer', false);
      this.$router.push({name});
    },
    handleElementClick(element) {
      this.$store.commit('setShowDrawer', false);
      if (element.action && this[element.action]) {
        this[element.action]();
      } else if (element.name) {
        this.$router.push({ name: element.name });
      }
    },
    logout() {
      // TODO: wylogowywanie
      this.$store.dispatch('userLogout').then(() => {
        this.$router.push({ name: 'Welcome' });
      });
    },
    goAdmin() {
      window.location.assign('https://fastcat.pl/admin');
    },
  },
}
</script>

<style scoped lang="scss">
.drawer-outer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.2);
  display: flex;
  justify-content: flex-end;
  .drawer {
    display: flex;
    flex-direction: column;
    min-height: initial !important;
    height: calc(100% - 20px);
    width: 300px;
    background-color: var(--primary);
    padding-bottom: 20px;

    .close_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .close_button {
        height: 60px;
        width: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: var(--primary);
        color: var(--white);
        transition: background-color .2s ease;

        &:hover, &:focus {
          background-color: var(--secondary);
        }
      }
    }

    .avatar_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;

      .avatar_content {
        width: 64px;
        height: 64px;
        padding: 6px;
        border-radius: 50%;
        border-width: 1px;
        border-style: solid;
        border-color: var(--border);
        background-color: var(--white);

        img {
          width: 64px;
          user-select: none;
        }
      }
    }

    .user_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      padding: 10px 0;
      cursor: pointer;
      transition: background-color .2s ease;

      .user_content {
        display: flex;
        flex-direction: column;

        .name {
          color: var(--white);
        }

        .email {
          font-size: 14px;
          color: var(--light-white);
        }
      }
      &:hover, &:focus {
        background-color: var(--secondary);
      }
    }

    .element_container {
      width: calc(100% - 40px);
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      background-color: var(--primary);
      color: var(--white);
      transition: background-color .2s ease;

      .element_content {

      }

      &:hover, &:focus {
        background-color: var(--secondary);
      }

      &.active {
        .element_content {
          font-weight: bold;
        }
      }
    }
    .bottom_container {
      .bottom_content {
        .simple_link {
          color: var(--light-white);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
